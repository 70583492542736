<template>
  <div id="exercise-log-card">
    <div class="card">
      <div class="card-body">
        <h1>{{ title }}</h1>
        <h2>{{ date.split('T')[0] }}</h2>
        <hr/>
        <br>
        <ul>
          <li v-if="routine.length > 0" v-for="exercise in routine"> {{ exercise.name }}</li>
          <li v-if="distance_miles != null"> Miles: {{ distance_miles.$numberDecimal }} </li>
          <li v-if="minutes != null"> Total Time: {{ minutes }}m <div v-if="seconds > 0">{{ String(seconds).padStart(2,'0') }}s</div></li>
          <li v-if="avg_minutes != null"> Avg Mile: {{ avg_minutes }}m <div v-if="avg_seconds > 0">{{ String(avg_seconds).padStart(2,'0') }}s</div></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExerciseLogCard',
  props: {
    title: String,
    date: String,
    routine: Array,
    duration_seconds: Object,
    distance_miles: Object,
  },
  setup(props) {
    const minutes = props.duration_seconds != null ? Math.floor(props.duration_seconds.$numberDecimal/60) : null;
    const seconds = props.duration_seconds != null ? props.duration_seconds.$numberDecimal - (60*minutes) : null;
    const avg_minutes = (props.duration_seconds != null && props.distance_miles != null) ? Math.floor(Math.round(props.duration_seconds.$numberDecimal/props.distance_miles.$numberDecimal)/60) : null;
    const avg_seconds = (props.duration_seconds != null && props.distance_miles != null) ? Math.round(props.duration_seconds.$numberDecimal/props.distance_miles.$numberDecimal) - (60*avg_minutes) : null;
    return { minutes, seconds, avg_minutes, avg_seconds }
  }
}
</script>

<style scoped>
#exercise-log-card {
  position:relative;
  height: 55vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

hr {
  border-color: black;
  width: 100%
}

.card {
  position:relative;
  height: 100%;
  width: 100%;
  border-radius:20px;
  padding: 20px;
  background: grey;
}

.card-body {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: center;
  color: white;
  justify-content: center;
  align-items: center;
}

.card-body ul {
  list-style-type: none;
  text-align: center;
}

li {
  font-size: 3vh;
}
</style>
