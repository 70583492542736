<template>
  <div id="MealCard">
    <div class="card">
      <div class="card-img">
        <img :src="photo" :alt="title"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MealCard',
  props: {
    title: String,
    photo: String,
  },
  setup(props) {}
}
</script>

<style scoped>
#MealCard {
  position:relative;
  height: 200px;
  max-width: 80vw;
  width: 100%;
}

.card {
  position:relative;
  height: 100%;
  width: 100%;
  transition: 0.3s;
  border-radius:20px;
}

img {
  position: relative;
  height: 100%;
  max-width: 100%;
  border-radius:20px;
}

.card-img {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
