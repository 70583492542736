<template>
<div id="career-home">
  <div class="flex-column">
    <div class="flex-column-header">
      <h1>Resume + Site Details</h1>
    </div>
    <div class="flex-column-body">
        <div class="flex-item">
          <CareerCard title="Resume" icon="resume.png" path="/resume"></CareerCard>
          <br/>
          <CareerCard title="About Site" icon="gear.png" path="/more-site"></CareerCard>
        </div>
    </div>
  </div>
</div>
</template>


<script>
import CareerCard from '@/components/CareerCard'

export default {
  name: 'CareerHome',
  components: { CareerCard },
  setup() {
  }
};
</script>

<style scoped>
#career-home {
  min-height: 100vh;
}

h1 {
  color:white;
  font-size: 5vh;
}

.flex-container {
  position: relative;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
  padding: 0 25% 0 25%;
  height: 100%;
}

.link {
  all: unset;
}
.flex-column-header {
  height: 10vh;
  text-align: center;
}
.flex-column-body {
  position: relative;
  color: white;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  gap: 5vh;
  height: 90vh;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}

.flex-item {
  position: relative;
  width: auto;
  margin: auto;
}
</style>
