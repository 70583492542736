import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `https://express.averyloftin.com/api/books/`

class BookService {
  getAllBooks() {
    return axios.get(API_URL);
  }

  getBook(id) {
    return axios.get(API_URL + `book/${id}`);
  }

  getBookPage(limit, offset) {
    return axios.get(API_URL + `${limit}/${offset}`);
  }

  createBook(book) {
    return axios.post(API_URL, book, { headers: { ...authHeader(), "Content-Type":"multipart/form-data" } });
  }

  updateBook(id, updated_fields) {
    return axios.patch(API_URL + `book/${id}`, updated_fields,
      { headers: { ...authHeader(), "Content-Type":"application/json" } });
  }

  addBookProgress(id, progress) {
    return axios.patch(API_URL + `book/${id}/progress`, progress, { headers: { ...authHeader(), "Content-type":"application/json" } });
  }

  getPhotoUrl(image_id) {
    return `https://express.averyloftin.com` + image_id
  }
}

export default new BookService();
