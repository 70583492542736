import authState from '@/controllers/authState';

export default function authHeader() {
  const { authToken } = authState;
  if (authToken) {
    return { Authorization: 'Bearer ' + authToken.value };
  } else {
    return {};
  }
}
