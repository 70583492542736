<template>
  <div id="book-home">
    <div class="flex-column">
      <div class="flex-column-header">
        <h1>Current Reading</h1>
      </div>
      <div class="flex-column-body">
          <div v-for="book in books_in_progress" class="flex-item">
            <BookCard :title="book.title" :author="book.author" :tags="book.tags" :cover="book.image" :pct_complete="book.percent_completion" :show_progress=true></BookCard>
          </div>
      </div>
      <div class="flex-column-footer" id="">
        <router-link to="/more-book" class="link">
          <button type="button" @click="">Full Library ></button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import BookCard from '@/components/BookCard'
import BookService from '@/services/book.service'
export default {
  name: 'BookHome',
  components: { BookCard },
  setup() {
    const all_books = ref([]);
    let books_in_progress = ref([]);


    onMounted(() => {
      BookService.getAllBooks().then(async (resp) => 
        { 
          let in_progress = resp.data.books.filter( (book) => { return book.status == 'In Progress' } )
                                        .map( (book) => {
                                           return new Promise( (resolve, reject) => {
                                             BookService.getBook( book._id )
                                               .then( resp => { resolve(resp.data.book) }, err => { reject(err); })
                                           }) 
                                         });
          let in_prog_resp = await Promise.all(in_progress)
          books_in_progress.value = in_prog_resp.map((book) => {
            return { 
              ...book,
              'image': BookService.getPhotoUrl(book.image),
              'percent_completion': ( book.progress.length > 0 ? book.progress[book.progress.length - 1].ending_page : 0 ) / book.pages * 100 
            }
          });
        }, err => { console.log('err'); });
    });

    return { books_in_progress }
  }
};
</script>

<style scoped>
.flex-column {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-column-header, .flex-column-footer {
  height: 5vh;
  text-align: center;
}

button {
  all: unset;
  border: 2px solid white;
  border-radius: 5px;
  padding: 2px;
}

h1 {
  color:white;
  font-size: 5vh;
}

.link {
  all: unset;
  color:white;
  font-size: 3vh;
}

#book-home {
  min-height: 100vh;
}

.flex-column-body {
  padding: 2vh 0 8vh 0;
  position: relative;
  display: flex;
  min-height: 79vh;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 7vh;
  justify-content: center;
  align-items: center;
}

.flex-item {
  position: relative;
  height: 100%;
}
</style>
