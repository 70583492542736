<template>
  <div id="CareerCard">
    <router-link class="link" :to="path">
      <div class="card">
        <div class="card-header">
          <h1>{{ title }}</h1>
        </div>
        <div class="card-img">
          <img :src="require(`@/assets/icons/${icon}`)" :alt="title"/>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'CareerCard',
  props: {
    title: String,
    icon: String,
    path: String,
  },
  setup(props) {
    return {}
  }
}
</script>

<style scoped>
#CareerCard {
  position:relative;
  height: 100%;
  width: 100%;
}

img {
  position: relative;
  height: 100px;
  max-width: 100px;
  border-radius:20px;
}

.card-img {
  position: relative;
  height: 100%;
  width: 100%;
}

.card {
  position:relative;
  display: flex;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;
  border-radius:20px;
  padding: 5px;
  background: grey;
}

.card-header {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  color: white;
}

.link {
  all: unset;
}
</style>
