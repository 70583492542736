import { createRouter, createWebHistory } from 'vue-router'
import authState from '@/controllers/authState';
import Home from '@/views/Home.vue'
import Register from '@/views/Register.vue'


const routes = [
  {
    path: '/',
    name: 'Index',
    component: Home
  },
  {
    path: '/books',
    name: 'Books',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "books" */ '@/views/Books.vue')
    }
  },
  {
    path: '/more-book',
    name: 'MoreBook',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "more_book" */ '@/views/MoreBook.vue')
    }
  },
  {
    path: '/blogs',
    name: 'Blogs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "blogs" */ '@/views/Blogs.vue')
    }
  },
  {
    path: '/exercise',
    name: 'ExerciseLogs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "exercise_logs" */ '@/views/ExerciseLogs.vue')
    }
  },
  {
    path: '/more-exercise',
    name: 'MoreExercise',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "more_exercise" */ '@/views/MoreExercise.vue')
    }
  },
  {
    path: '/measurements',
    name: 'Measurements',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "measurements" */ '@/views/Measurements.vue')
    }
  },
  {
    path: '/meals',
    name: 'Meals',
    component: function () {
      return import(/* webpackChunkName: "meals" */ '@/views/Meals.vue')
    }
  },
  {
    path: '/more-meal',
    name: 'MoreMeal',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "more_meal" */ '@/views/MoreMeal.vue')
    }
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/resume',
    name: 'Resume',
    component: function () {
      return import(/* webpackChunkName: "resume" */ '@/views/Resume.vue')
    }
  },
  {
    path: '/more-site',
    name: 'MoreSite',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "more_site" */ '@/views/MoreSite.vue')
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/resume', '/more-exercise', '/more-meal', '/more-book', '/more-site'];
  const authRequired = !publicPages.includes(to.path);
  const { expirationCheck, isLoggedIn } = authState;

  expirationCheck(); 

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (!authRequired || isLoggedIn.value) {
    next();
  }
})

export default router
