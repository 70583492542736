<template>
  <div id="BookCard">
    <div class="card">
      <div class="card-img">
        <img :src="cover" alt="Book Cover"/>
      </div>
      <div class="card-body">
        <h1>{{ title }}</h1>
        <h2>by {{ author }}</h2>
        <ul>
          <li v-for="tag in tags"> {{ tag }}</li>
        </ul>
      </div>
      <div class="meter" :hidden="!show_progress">
        <span :style="{width: parseFloat(pct_complete) + '%'}"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BookCard',
  props: {
    title: String,
    author: String,
    tags: Array,
    cover: String,
    pct_complete: Number,
    show_progress: Boolean,
  },
  setup(props) {
  }
}
</script>

<style scoped>
#BookCard {
  position:relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.card {
  height: 70vh;
  position:relative;
  transition: 0.3s;
}

img {
  height: 100%;
  position: relative;
  border-radius: 5px;
}

.card-img {
  position: relative;
  height: 100%;
  width: 100%;
}

.card-body {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0;
  text-align: center;
  color: white;
}

.card-body:hover {
  background-color: rgba(0,0,0,0.9);
  border-radius:20px;
  font-size: initial;
}

.card-body ul {
  list-style-type: none;
  text-align: center;
}

.meter { 
  position: relative;
  height: 2vh;
  background: black;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  padding: 1vh;
  margin-top: 1vh;
  box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
}

.meter > span {
  display: block;
  height: 2vh;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: rgb(20%, 20%, 20%);
  background-image: linear-gradient(
    center bottom,
    rgb(43,194,83) 37%,
    rgb(84,240,84) 69%
  );
  box-shadow: 
    inset 0 2px 9px  rgba(255,255,255,0.3),
    inset 0 -2px 6px rgba(0,0,0,0.4);
  position: relative;
  overflow: hidden;
}

</style>
