<template>
  <transition v-if="showModal" name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <button class="modal-close-button" @click="showModal = !showModal">X</button>
            <h3>Login</h3>
          </div>

          <div class="modal-body">
            <form name="form" @submit.prevent="handleLogin">
              <label for="username">Username</label>
              <input
                v-model="formUser.username"
                type="text"
                name="username"
              />
              <label for="password">Password</label>
              <input
                v-model="formUser.password"
                type="password"
                name="password"
              />
              <br>
              <div id='login-error' v-if="errorMessage" role="alert">{{errorMessage}}</div>
              <button :disabled="isLoggingIn">
                <span v-show="isLoggingIn"></span>
                <span>Login</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import authState from '@/controllers/authState';
import User from '@/models/user';
import { reactive, ref } from "vue";
import router from '@/router';

export default {
  props: { showModal: Boolean },
  setup( props ) {
    const { login, isLoggingIn, isLoggedIn } = authState;
    const formUser = reactive(new User('', ''));
    let errorMessage = ref('');

    function handleLogin() {
      if (formUser.username && formUser.password) {
        login(formUser).then( 
          resp => {
            this.formUser = new User('','');
            errorMessage.value = '';
          },
          error => {
            this.formUser = new User('','');
            errorMessage.value = 'Login Failed.';
          }
        );
      }
    };

    return { handleLogin, isLoggingIn, errorMessage, formUser };
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.5s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 50vw;
  max-width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: rgba(30, 30, 30, 1);
  border-radius: 10px;
  box-shadow: 6px 6px 18px rgba(200, 200, 200, 0.3);
  transition: all 0.5s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: white;
}

.modal-body {
  margin: 20px 0;
}

.modal-close-button {
  float: right;
}

label {
  display: block;
  margin-top: 10px;
  color: white;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

#login-error {
  color: white;
}
</style>
