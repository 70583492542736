<template>
  <div class="card card-container">
    <h1>Register</h1>
    <form name="form" @submit.prevent="handleRegister">
        <label for="username">Username</label>
        <input
          v-model="formUser.username"
          type="text"
          name="username"
        />
        <label for="password">Password</label>
        <input
          v-model="formUser.password"
          type="password"
          name="password"
        />
        <button :disabled="isRegistering">
          <span v-show="isRegistering"></span>
          <span>Sign Up</span>
        </button>
        <div v-if="errorMessage" role="alert">{{errorMessage}}</div>
    </form>
  </div>
</template>

<script>
import authState from '@/controllers/authState';
import User from '@/models/user';
import { reactive, ref } from "vue";
import router from '@/router';

export default {
  name: 'Register',

  setup() {
    const { register, isRegistering, isLoggedIn } = authState;
    const formUser = reactive(new User('', ''));
    let errorMessage = ref('');

//    if (isLoggedIn) {
//      return router.push('/books');
//    }

    function handleRegister() {
      if ( formUser.username && formUser.password ) {
        register(formUser).then(
          resp => {
 //            router.push('/login');
          },
          error => {
            errorMessage.value =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        )
      }
    }

    return { formUser, isRegistering, handleRegister, errorMessage }
  }
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>
