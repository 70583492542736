import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `https://express.averyloftin.com/api/exercise_logs/`

class ExerciseLogService {
  getAllExerciseLogs() {
    return axios.get(API_URL);
  }

  getExerciseLog(id) {
    return axios.get(API_URL + `exercise_log/${id}`);
  }

  getExerciseLogPage(limit, offset) {
    return axios.get(API_URL + `${limit}/${offset}`);
  }

  createExerciseLog(exercise_log) {
    return axios.post(API_URL, exercise_log, { headers: authHeader() });
  }
}

export default new ExerciseLogService();
