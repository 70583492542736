<template>
  <div id="home">
    <div class="flex-container">
      <div class="flex-item" id="img-item">
        <img :src="back_photo" id="background-img" alt="Image of Avery Loftin"/>
        <div class="grid-container">
          <div class="grid-item top left" id="topleft-grid-item">
            <a href="#book-section">
              <img src="@/assets/icons/book.png" alt="Book Icon"/>
            </a>
          </div>
          <div class="grid-item top right" id="topright-grid-item">
            <a href="#lifting-section">
              <img src="@/assets/icons/barbell.png" alt="Barbell Icon"/>
            </a>
          </div>
          <div class="grid-item bottom left" id="bottomleft-grid-item">
            <a href="#food-section">
              <img src="@/assets/icons/food.png" alt="Food Icon"/>
            </a>
          </div>
          <div class="grid-item bottom right" id="bottom-right-grid-item">
            <a href="#career-section">
              <img src="@/assets/icons/resume.png" alt="Resume Icon"/>
            </a>
          </div>
        </div>
      </div>
      <div class="flex-item" id="book-section">
        <BookHome></BookHome>
      </div>
      <div class="flex-item" id="lifting-section">
        <ExerciseHome></ExerciseHome>
      </div>
      <div class="flex-item" id="food-section">
        <MealHome></MealHome>
      </div>
      <div class="flex-item" id="career-section">
        <CareerHome></CareerHome>
      </div>
    </div>
  </div>
</template>

<script>
import BookHome from '@/components/BookHome'
import ExerciseHome from '@/components/ExerciseHome'
import CareerHome from '@/components/CareerHome'
import MealHome from '@/components/MealHome'
import AssetService from '@/services/asset.service';
export default {
  name: 'Home',
  components: { BookHome, ExerciseHome, MealHome, CareerHome },
  setup() {
    let back_photo = AssetService.getAssetURL('OverShoulder.png');
    return { back_photo }
  }
};
</script>

<style scoped>
.flex-container {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 5vh;
}

.flex-item {
  position: relative;
  width: 100%;
  min-height: 100vh;
}

#background-img {
  height: 100vh;
}

#img-item {
  position:relative;
}

.grid-container {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 1vh 1vw;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right {
  justify-content: flex-start;
}
.left {
  justify-content: flex-end;
}
.top {
  align-items: flex-end;
}
.bottom {
  align-items: flex-start;
}

.grid-item img {
  max-height: 10vh;
  max-width: 10vh;
  padding: 10px;
  background: rgba(20%, 20%, 20%, .25);
}

.grid-item img:hover {
  max-height: 12vh;
  max-width: 12vw;
}

@media only screen and (max-width:100vh) {
    #img-item {max-width:100vh; overflow:hidden;}
}
</style>
