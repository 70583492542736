<template>
  <div id="exercise-home">
    <div class="flex-column">
      <div class="flex-column-header">
        <h1>Last 3 Exercises</h1>
      </div>
      <div class="flex-column-body">
          <div v-for="exercise_log in past_three_exercise_logs" class="flex-item">
            <ExerciseLogCard :title="exercise_log.type" :date="exercise_log.date" :routine="exercise_log.routine" :duration_seconds="exercise_log.duration_seconds" :distance_miles="exercise_log.distance_miles"></ExerciseLogCard>
          </div>
      </div>
      <div class="flex-column-footer">
        <router-link to="/more-exercise" class="link">
          <button type="button" @click="">Q&A ></button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import ExerciseLogService from '@/services/exercise_log.service'
import ExerciseLogCard from '@/components/ExerciseLogCard';

export default {
  name: 'ExerciseHome',
  components: { ExerciseLogCard },
  setup() {
    const past_three_exercise_logs = ref([]);
    
    onMounted(() => {
      ExerciseLogService.getExerciseLogPage(3,0).then(
        async resp => {
          let promised_log_details = resp.data.exercise_logs.map( (log) => {
              return new Promise( (resolve, reject) => {
                  ExerciseLogService.getExerciseLog( log._id ).then(
                      resp2 => { resolve(resp2.data.exercise_log) }, err => { reject(err); }
                  )
              } )
          } )
          past_three_exercise_logs.value = await Promise.all(promised_log_details);
        },
        err => { console.log(err); }
      );
    });

    return {
      past_three_exercise_logs
    }
  }
};
</script>

<style scoped>
.flex-column {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.flex-column-header, .flex-column-footer {
  height: 5vh;
  text-align: center;
}

.flex-column-body {
  padding: 5vh 0 5vh 0;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  flex-gap: 5vh;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

button {
  all: unset;
  border: 2px solid white;
  border-radius: 5px;
  padding: 2px;
}

h1 {
  color:white;
  font-size: 5vh;
}

.link {
  all: unset;
  color:white;
  font-size: 3vh;
}

#exercise-home {
  min-height: 100vh;
}

.flex-container {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.flex-item {
  padding: 5vh;
}
</style>
