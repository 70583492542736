import axios from 'axios';

const API_URL = `https://express.averyloftin.com`
const ASSET_URL = `${API_URL}/api/assets/`

class AssetService {
  getAsset(file_name) {
    return axios.get(ASSET_URL + file_name);
  }

  getAssetURL(file_name) {
    return ASSET_URL + file_name;
  }

  getPhotoURL(api_path) {
    return API_URL + api_path;
  }
}

export default new AssetService();
