<template>
  <div id="Navbar">
    <div class="nav-wrapper" id="nav-wrapper">
      <nav>
        <li class="icon-list">
          <router-link to="/">
            <img src="@/assets/icons/home.png"/>
          </router-link>
        </li>
        <div v-if="!isLoggedIn">
          <li class="icon-list">
            <img src="@/assets/icons/white-lock.png" v-on:click="toggledLoginModal = !toggledLoginModal;"/>
          </li>
        </div>
        <div v-if="isLoggedIn">
          <li class="icon-list" id="dropdown">
            <img src="@/assets/icons/dropdown-arrow.png" v-on:click="toggleDropdown"/>
          </li>
          <li class="icon-list">
            <img src="@/assets/icons/logout-icon.png" v-on:click="logout_and_go_home"/>
          </li>
        </div>

        <div v-if="isAdmin" id="route-list">
          <li>
            <router-link to="/books" class="nav-link">
              Books
            </router-link>
          </li>
          <li>
            <router-link to="/blogs" class="nav-link">
              Blogs
            </router-link>
          </li>
          <li>
            <router-link to="/exercise" class="nav-link">
              Exercise
            </router-link>
          </li>
            <li>
            <router-link to="/meals" class="nav-link">
              Meals
            </router-link>
          </li>
          <li>
            <router-link to="/measurements" class="nav-link">
              Measurements
            </router-link>
          </li>
        </div>
      </nav>
    </div>
    <LoginModal :showModal="showLoginModal"></LoginModal>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import authState from '@/controllers/authState';
import LoginModal from '@/components/LoginModal';

export default {
  components: { LoginModal },
  setup( context ) {
    const { isLoggedIn, isAdmin, logout } = authState;
    const router = useRouter();
    const toggledLoginModal = ref(false);
    const showLoginModal = computed(() => { return !isLoggedIn.value && toggledLoginModal.value });

    router.beforeEach(() => { 
      let x = document.getElementById("nav-wrapper");
      x.className = "nav-wrapper";
     })

    function toggleDropdown() {
      let x = document.getElementById("nav-wrapper");
      if (x.className === "nav-wrapper") {
        x.className += " responsive";
      } else {
        x.className = "nav-wrapper";
      }
    }

    function logout_and_go_home() {
      logout()
      toggledLoginModal.value = false;
      router.push('/')
    }

    return { toggledLoginModal,  showLoginModal, isLoggedIn, isAdmin, logout_and_go_home, toggleDropdown };
  }
};
</script>

<style scoped>
p {
  color: white;
}
nav {
  height: 7vh;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
nav:hover {
  background: rgba(20%, 20%, 20%, .25);
}
#route-list {
  background: dimgrey;
}
li {
  float: right;
  line-height: 7vh;
  vertical-align: middle;
}
.nav-link {
  display: block;
  color: white;
  text-align: center;
  padding-right: 8px;
  padding-left: 8px;
  text-decoration: none;
}
li:hover {
  background: rgba(20%, 20%, 20%, .25);
}
.icon-list {
  padding-right: 10px;
  padding-left: 10px;
  line-height: 7vh;
  vertical-align: middle;
  float: right;
}
img {
  height: 3vh;
  line-height: 7vh;
  vertical-align: middle;
}
#dropdown {
  display: none;
}


@media only screen and (max-width:93vh) {
  .nav-wrapper #route-list {
    display: none;
  }
  .nav-wrapper #dropdown {
    display: block;
  }
}
@media only screen and (max-width:93vh) {
  .nav-wrapper.responsive #route-list {
    position: relative;
  }
  .nav-wrapper.responsive #route-list {
    position: relative;
    z-index: -1;
    display: block;
    padding-top: 7vh;
  }
  .nav-wrapper.responsive #route-list li {
    float: none;
  }
}
</style>
