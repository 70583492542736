import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `https://express.averyloftin.com/api/meals/`

class MealService {
  getAllMeals() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  getMeal(id) {
    return axios.get(API_URL + `meal/${id}`, { headers: authHeader() });
  }

  getMealPage(limit, offset) {
    return axios.get(API_URL + `${limit}/${offset}`, { headers: authHeader() });
  }

  createMeal(meal) {
    return axios.post(API_URL, meal, { headers: { ...authHeader(), "Content-Type":"multipart/form-data" } });
  }

  updateMeal(id, updated_fields) {
    return axios.patch(API_URL + `meal/${id}`, updated_fields,
      { headers: { ...authHeader(), "Content-Type":"application/json" } });
  }

  addMealIngredient(id, ingredient) {
    return axios.patch(API_URL + `meal/${id}/ingredients`, ingredient, { headers: { ...authHeader(), "Content-type":"application/json" } });
  }
}

export default new MealService();
