import axios from 'axios';

const API_URL = `https://express.averyloftin.com/api/users/`

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'login', {
        username: user.username,
        password: user.password
      }, { headers: { "Content-type":"application/json" } })
      .then(response => {
        //if (response.data.token) {
        //  localStorage.setItem('user', JSON.stringify(response.data));
        //}
        console.log(response);
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      password: user.password
    }, { headers: { "Content-type":"application/json" } });
  }
}

export default new AuthService();
