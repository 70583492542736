<template>
  <div id="meal-home">
    <div class="flex-column">
      <div class="flex-column-header">
        <h1>Meals</h1>
      </div>
      <div class="flex-column-body">
          <div v-for="meal in current_meals" class="flex-item">
            <MealCard :title="meal.title" :photo="meal.image"></MealCard>
          </div>
      </div>
      <div class="flex-column-footer">
        <router-link to="/more-meal" class="link">
          <button type="button" @click="">See Ingredients ></button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import MealCard from '@/components/MealCard'
import MealService from '@/services/meal.service'
import AssetService from '@/services/asset.service'
export default {
  name: 'MealHome',
  components: { MealCard },
  setup() {
    const current_meals = ref([]);
    const MEAL_ORDER = ['Breakfast', 'Snack', 'Lunch 1', 'Lunch 2', 'Dinner', 'Dessert']

    onMounted(() => {
      MealService.getAllMeals().then(async (resp) => 
        {
          let curr_version_meals = resp.data.meals.filter( (meal) => { return meal.version == 1 } )
                                        .map( (meal) => {
                                           return new Promise( (resolve, reject) => {
                                             MealService.getMeal( meal._id )
                                               .then( resp => { resolve(resp.data.meal) }, err => { reject(err); })
                                           }) 
                                         });
          let curr_version_resp = await Promise.all(curr_version_meals)
          current_meals.value = curr_version_resp.map((meal) => {
            return { 
              ...meal,
              'image': AssetService.getPhotoURL(meal.image),
            }
          }).sort(function(x,y) {
             return MEAL_ORDER.indexOf(x.type) < MEAL_ORDER.indexOf(y.type) ? -1 : 1;
          });
        }, err => { console.log('err'); });
    });

    return { current_meals }
  }
};
</script>

<style scoped>
.flex-column {
  position: relative;
  display: flex;
  flex-direction: column;
  height:100%;
  width: 100%;
}
.flex-column-header, .flex-column-footer {
  height: 5vh;
  text-align: center;
}

.flex-column-body {
  padding: 5vh 0 5vh 0;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  all: unset;
  color:white;
  font-size: 3vh;
}

button {
  all: unset;
  border: 2px solid white;
  border-radius: 5px;
  padding: 2px;
}

h1 {
  color:white;
  font-size: 5vh;
}

#meal-home {
  min-height:100vh;
}

.flex-item {
  position: relative;
  height: 100%;
}
</style>
