<template>
  <div id="app">
    <div id="navbar-container">
      <Navbar></Navbar>
    </div>
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import authState from '@/controllers/authState';
import Navbar from '@/components/Navbar';

export default {
  components: { Navbar }
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  font-family: Ubuntu;
}

body {
  background-color: black;
}

#app {
  position: relative;
}

#navbar-container {
  position: absolute;
  z-index: 1;
  width: 100%;
}

.container {
  position: relative;
  padding-top: 7vh;
}
</style>
