import AuthService from '../services/auth.service';
import { reactive, computed } from 'vue';

const state = reactive({
  status: { 
    loggingIn: false,
    loggedIn: false,
    registering: false,
  },
  user: null
});

let isLoggingIn = computed(() => state.status.loggingIn);
let isRegistering = computed(() => state.status.registering);

let isLoggedIn = computed(() => state.status.loggedIn);
let isAdmin = computed(() => state.user ? state.user.admin : false );

let authToken = computed(() => state.user ? state.user.token : null);

const login = ((user) => {
  state.status.loggingIn = true;

  return AuthService.login(user).then(
    user => {
      state.status.loggedIn = true;
      state.user = user;
      state.status.loggingIn = false;
      return Promise.resolve(user); 
    },
    error => {
      state.status.loggedIn = false;
      state.user = null;
      state.status.loggingIn = false;
      return Promise.reject(error); 
    }
  );
});

const logout = (() => {
  AuthService.logout();
  state.status.loggedIn = false;
  state.user = null;
});

const register = ((user) => {
  state.status.registering = true;

  return AuthService.register(user).then(
    response => {
      state.status.loggedIn = false;
      state.user = null;
      state.status.registering = false;
      return Promise.resolve(response.data);
    },
    error => {
      state.status.loggedIn = false;
      state.user = null;
      state.status.registering = false;
      return Promise.reject(error);
    }
  );
});

const expirationCheck = (() => {
  if ( ( state.user == null ) || ( (new Date() / 1000) > state.user.expiration ) ) {
    logout();
  }
});

export default {
    isLoggedIn,
    isLoggingIn,
    isRegistering,
    isAdmin,
    authToken,
    login,
    logout,
    register,
    expirationCheck
};
